import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "@app/api/api.service";
import {
  Attachment,
  ATTACHMENT_MODEL,
  DIVE_OPERATION_MODEL,
  IAttachmentForm,
} from "@app/models";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { from, of } from "rxjs";
import {
  catchError,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
} from "rxjs/operators";
import {
  OfflineQueueApiActions,
  OperationApiActions,
  OperationViewPageActions,
} from "../actions";
import { getApiId } from "../model";
import {
  selectAttachment,
  selectAttachmentForm,
  selectAttachmentId,
  selectOperationId,
  selectOperationSyncOptions,
} from "../selectors";
import {
  AttachmentApiActions,
  AttachmentListPageActions,
  AttachmentPreviewActions,
  AttachmentPutPageActions,
  AttachmentViewPageActions,
} from "./actions";

export const PATH_ID = "attachments";

export function getFileReader(): FileReader {
  const fileReader = new FileReader();
  const zoneOriginalInstance = (fileReader as any)[
    "__zone_symbol__originalInstance"
  ];
  return zoneOriginalInstance || fileReader;
}

export function blobToBase64(blob: Blob) {
  return new Promise<string>((resolve, reject) => {
    const reader = getFileReader();
    reader.onerror = reject;
    reader.onload = () => resolve(reader.result.toString().split(",")[1]);
    reader.readAsDataURL(blob);
  });
}

export const ATTACHMENT_CONCURRENCY = 4;
export const ATTACHMENT_TIMEOUT = 200000;

@Injectable()
export class AttachmentsEfffects {
  private apiService = inject(ApiService);

  url = ["/", "attachments"];

  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router
  ) {}

  searchOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationApiActions.searchOperationsSuccess),
      map(() => AttachmentApiActions.searchAttachments())
    )
  );

  searchAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AttachmentApiActions.searchAttachments,
        AttachmentListPageActions.searchAttachments
      ),
      switchMap(() =>
        this.apiService.search(ATTACHMENT_MODEL).pipe(
          map((apiData) =>
            AttachmentApiActions.searchAttachmentsSuccess({
              attachments: apiData.map((item) => new Attachment(item)),
            })
          ),
          catchError((error) =>
            of(AttachmentApiActions.searchAttachmentsFailure({ error }))
          )
        )
      )
    )
  );

  ensureAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentApiActions.searchAttachmentsSuccess),
      switchMap(({ attachments }) =>
        from(attachments).pipe(
          mergeMap((attachment) => {
            const { _id, mimetype } = attachment;
            return this.apiService
              .ensureFile(ATTACHMENT_MODEL, _id, mimetype)
              .pipe(
                map((thumbnail: string) =>
                  AttachmentApiActions.ensureAttachmentSuccess({
                    attachment: { ...attachment, thumbnail },
                  })
                ),
                catchError((error) =>
                  of(
                    AttachmentApiActions.ensureAttachmentFailure({
                      attachment,
                      error,
                    })
                  )
                )
              );
          }, ATTACHMENT_CONCURRENCY)
        )
      )
    )
  );

  openAttachmentListPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OperationViewPageActions.openAttachments),
        concatLatestFrom(() => this.store.select(selectOperationId)),
        tap(([, operationId]) => {
          this.router.navigate([...this.url, "operation", operationId]);
        })
      ),
    { dispatch: false }
  );

  openAttachmentCreatePage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AttachmentListPageActions.createAttachment),
        concatLatestFrom(() => this.store.select(selectOperationId)),
        tap(([, operationId]) =>
          this.router.navigate([...this.url, "create", operationId])
        )
      ),
    { dispatch: false }
  );

  openAttachmentViewPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AttachmentPreviewActions.openAttachmentViewPage,
          AttachmentApiActions.createAttachmentSuccess,
          AttachmentApiActions.updateAttachmentSuccess
        ),
        tap(({ type, attachment }) =>
          this.router.navigate([...this.url, "view", getApiId(attachment)], {
            replaceUrl:
              type == AttachmentApiActions.createAttachmentSuccess.type ||
              type == AttachmentApiActions.updateAttachmentSuccess.type,
          })
        )
      ),
    { dispatch: false }
  );

  openAttachmentEditPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AttachmentViewPageActions.updateAttachment),
        concatLatestFrom(() => this.store.select(selectAttachmentId)),
        tap(([, attachmentId]) =>
          this.router.navigate([...this.url, "edit", attachmentId])
        )
      ),
    { dispatch: false }
  );

  setOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentPutPageActions.setAttachment),
      concatLatestFrom(() => this.store.select(selectAttachment)),
      map(([, { res_id }]) =>
        AttachmentApiActions.setOperation({ operationId: res_id })
      )
    )
  );

  openAttachmentFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AttachmentViewPageActions.openAttachment),
        concatLatestFrom(() => this.store.select(selectAttachment)),
        switchMap(([, attachment]) =>
          this.apiService.openFile(
            ATTACHMENT_MODEL,
            attachment._id,
            attachment.mimetype
          )
        )
      ),
    { dispatch: false }
  );

  downloadAttachmentFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AttachmentViewPageActions.downloadAttachment),
        concatLatestFrom(() => this.store.select(selectAttachment)),
        switchMap(([, attachment]) =>
          this.apiService.downloadFile(
            ATTACHMENT_MODEL,
            attachment._id,
            attachment.name,
            attachment.mimetype
          )
        )
      ),
    { dispatch: false }
  );

  setAttachmentForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AttachmentPutPageActions.setOperation,
        AttachmentPutPageActions.setAttachment
      ),
      concatLatestFrom(() => this.store.select(selectAttachment)),
      map(([, attachment]) =>
        AttachmentApiActions.setAttachmentForm({
          attachmentFormValue: {
            _id: attachment._id,
            name: attachment.name,
            description: attachment.description,
            mimetype: attachment.mimetype,
          },
          thumbnail: attachment.thumbnail,
        })
      )
    )
  );

  setAttachmentFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentPutPageActions.setAttachmentFile),
      concatLatestFrom(() => this.store.select(selectAttachmentForm)),
      switchMap(([{ fileBlob }, form]) => {
        const fileId = form.value._id;
        return this.apiService
          .saveFile(ATTACHMENT_MODEL, fileId, fileBlob)
          .pipe(
            map((thumbnail) =>
              AttachmentPutPageActions.setAttachmentFileSuccess({
                name: fileBlob.name,
                mimetype: fileBlob.type,
                thumbnail,
              })
            )
          );
      })
    )
  );

  createAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentPutPageActions.createAttachment),
      concatLatestFrom(() => [
        this.store.select(selectOperationId),
        this.store.select(selectAttachmentForm),
        this.store.select(selectOperationSyncOptions),
      ]),
      exhaustMap(([, operationId, form, syncOptions]) => {
        if (!form.isValid) {
          return of(AttachmentApiActions.attachmentFormInvalid());
        }
        const values: IAttachmentForm = {
          _id: form.value._id,
          name: form.value.name,
          mimetype: form.value.mimetype,
          description: form.value.description,
          res_id: operationId,
          res_model: DIVE_OPERATION_MODEL,
        };
        return this.apiService
          .create(ATTACHMENT_MODEL, values, syncOptions)
          .pipe(
            map((apiData) =>
              AttachmentApiActions.createAttachmentSuccess({
                attachment: new Attachment({
                  ...apiData,
                  thumbnail: form.userDefinedProperties["thumbnail"],
                }),
              })
            ),
            catchError((error) =>
              of(AttachmentApiActions.createAttachmentFailure({ error }))
            )
          );
      })
    )
  );

  updateAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentPutPageActions.updateAttachment),
      concatLatestFrom(() => [
        this.store.select(selectAttachmentId),
        this.store.select(selectAttachmentForm),
        this.store.select(selectOperationSyncOptions),
      ]),
      exhaustMap(([, attachmentId, form, syncOptions]) => {
        if (!form.isValid) {
          return of(AttachmentApiActions.attachmentFormInvalid());
        }
        const values: IAttachmentForm = {
          _id: form.value._id,
          name: form.value.name,
          mimetype: form.value.mimetype,
          description: form.value.description,
        };
        return this.apiService
          .update(ATTACHMENT_MODEL, attachmentId, values, syncOptions)
          .pipe(
            map((apiData) =>
              AttachmentApiActions.updateAttachmentSuccess({
                attachment: new Attachment({
                  ...apiData,
                  thumbnail: form.userDefinedProperties["thumbnail"],
                }),
              })
            ),
            catchError((error) =>
              of(AttachmentApiActions.updateAttachmentFailure({ error }))
            )
          );
      })
    )
  );

  deleteAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentViewPageActions.deleteAttachment),
      concatLatestFrom(() => [
        this.store.select(selectOperationId),
        this.store.select(selectAttachmentId),
        this.store.select(selectOperationSyncOptions),
      ]),
      switchMap(([, operationId, attachmentId, enequeueOptions]) =>
        this.apiService.deleteFile(ATTACHMENT_MODEL, attachmentId).pipe(
          switchMap(() =>
            this.apiService.delete(
              ATTACHMENT_MODEL,
              attachmentId,
              enequeueOptions
            )
          ),
          map(() =>
            AttachmentApiActions.deleteAttachmentSuccess({ attachmentId })
          ),
          tap(() => {
            const url = [...this.url, "operation", operationId.toString()];
            this.router.navigate(url, { skipLocationChange: true });
          }),
          catchError((error) =>
            of(AttachmentApiActions.deleteAttachmentFailure({ error }))
          )
        )
      )
    )
  );

  syncAttachmentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OfflineQueueApiActions.ApiCreateSuccess,
        OfflineQueueApiActions.ApiUpdateSuccess
      ),
      filter(({ model }) => model == ATTACHMENT_MODEL),
      map(({ data }) =>
        AttachmentApiActions.syncAttachmentSuccess({
          attachment: new Attachment(data),
        })
      )
    )
  );
}
